import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { useForm,useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import {_roles, _tags, USER_STATUS_OPTIONS} from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import SettingsIcon from '@mui/icons-material/Settings';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {RHFSelect, RHFTextField, RHFAutocomplete, RHFEditor, RHFUpload, RHFSwitch, RHFCheckbox} from 'src/components/hook-form';
import {AccordionDetails, Autocomplete, CircularProgress, LinearProgress, TextField, Tooltip} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tabs, {tabsClasses} from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";

import {useAxStore} from "../../store/axStore";
import axios from "../../utils/axios";
import {useResponsive} from "../../hooks/use-responsive";
import ConfirmDepartmentDelete from "./ConfirmDepartmentDelete";
import {useAuthContext} from "../../auth/hooks";
import CompanyQuickEditForm from "../company/company-quick-edit-form";
import UserQuickEditForm from "../user/user-quick-edit-form";
import {useBoolean} from "../../hooks/use-boolean";

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'zakladne-nastavenia',
    label: 'Základné nastavenia',
    icon: <Iconify icon="solar:battery-full-minimalistic-bold" width={24} />,
  },
   {
    value: 'maintenance',
    label: 'Maintenance',
    icon: <Iconify icon="solar:settings-bold" width={24} />,
  },
  {
    value: 'newsletter',
    label: 'Newsletter',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  /* {
    value: 'db-ftp',
    label: 'DB a FTP',
    icon: <Iconify icon="solar:database-bold" width={24} />,
  },

  {
    value: 'todo',
    label: 'Todo',
    icon: <Iconify icon="solar:battery-charge-minimalistic-bold" width={24} />,
  },
  {
    value: 'logy',
    label: 'Logy',
    icon: <Iconify icon="solar:battery-full-minimalistic-bold" width={24} />,
  },
*/
];
// ----------------------------------------------------------------------

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k**i).toFixed(dm))  } ${  sizes[i]}`;
}
function ConvertToNiceDate(mysql_timestamp) {
  // console.log('ConvertToNiceDate working with ',mysql_timestamp);
  if((mysql_timestamp!=="0000-00-00 00:00:00")&&(mysql_timestamp!=="0000-00-00")) {
    const optionsDate = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    const optionsTime = {
      hour: 'numeric',
      minute: 'numeric',
    };
    const optionsDay = {
      weekday: 'long'
    };

    const dateFormatter = new Intl.DateTimeFormat('sk-SK', optionsDate);
    const timeFormatter = new Intl.DateTimeFormat('sk-SK', optionsTime);
    const dayFormatter = new Intl.DateTimeFormat('sk-SK', optionsDay);
    const timestamp_created = new Date(mysql_timestamp);
    // return dateFormatter.format(timestamp_created);

    // eslint-disable-next-line no-unreachable
    const niceDateObject={
      niceDate:dateFormatter.format(timestamp_created),
      niceTime: timeFormatter.format(timestamp_created),
      niceday: dayFormatter.format(timestamp_created)
    }
    return niceDateObject
  }return false;
} // returns an object with date, time, day_in_week_by_name



export default function AxPixmeQuickEditForm({ open, onClose }) {
  const mdUp = useResponsive('up', 'md');
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [isAnalyzingAiImage, setIsAnalyzingAiImage] = useState(false)

  const axStore=useAxStore()
  const user=axStore.axCurrentUser


  const [dialogWidth, setDialogWidth] = useState("md")
  // *****user states
  const [currentTab, setCurrentTab] = useState('zakladne-nastavenia');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const onError = (errors, e) => {
    enqueueSnackbar("Chyba pri validácii, chck console",{ variant: `error`,autoHideDuration: 6000 });
    console.log('submit validation error:',errors, e)
  }

  const axSchema = Yup.object().shape({
    depname: Yup.string().when('depIdToEdit', {
      is: depId => depId > 0,
      then: schema=>schema.required('Názov departmentu je povinná položka'),
      otherwise: schema => schema.notRequired()
    }),

    website_canonic_url: Yup.string().when('depIdToEdit', {
      is: depId => depId > 0,
      then: schema=>schema.url('Táto položka musí byť v tvare webovej adresy, napr. https://pixme.sk'),
      otherwise: schema => schema.notRequired()
    }),

  });

  const defaultValues = useMemo(
    () =>({
      /* -------------- NOVY DEPARTMENT  ----------------*/
        s3:{},

       s3_provider: axStore.systemCurrentConfig?.s3?.selected_s3?.toString() || 0,
       allow_pixme_upload_globally: axStore.systemCurrentConfig?.main?.allow_upload?true:false || false,



    }),
// eslint-disable-next-line react-hooks/exhaustive-deps
    // [currentDep === null ? null : currentDep.id]
// eslint-disable-next-line react-hooks/exhaustive-deps
     []
  );



  const methods = useForm({
    resolver: yupResolver(axSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    dirtyFields,
    setIsDirty,
    setValue,
    getValues,
    watch,
    control,
    formState: { isSubmitting, isDirty,touchedFields },
  } = methods;


 const isChangingS3Provider=useBoolean(false)

  const handleChangeS3 = (event) => {
    const s3ProviderValue = event.target.value;
    // console.log("Selected value:", s3ProviderValue);
    // axStore.setSystemCurrentConfig({ ...axStore.systemCurrentConfig, s3: response.data.data.ax_settings.s3 })
    // axStore.setSystemCurrentConfig({ ...axStore.systemCurrentConfig.s3, selected_s3: s3ProviderValue })

    if(!isChangingS3Provider.value) {

      isChangingS3Provider.onTrue()

      const jwt = localStorage.getItem('axjwt');
      if (jwt) {
        const postValues = new FormData();
        postValues.append('action', 'change_s3_provider');
        postValues.append('toS3', s3ProviderValue);

        axios.post('https://api.administrix.pro/v1/post/',
          postValues, {
            headers: {'Authorization': `Bearer ${jwt}`}
          })
          .then((response => {
            if (response.data.data) {
              const {data} = response.data;
              const {meta} = response.data;
              if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
              if (data.s3) {
                enqueueSnackbar("S3 providera sme úspešne zmenili.", {variant: `success`, autoHideDuration: 6000});
                axStore.setSystemCurrentConfig({ ...axStore.systemCurrentConfig, s3: data.s3 })
                setValue('s3_provider',data.s3.selected_s3)
                // console.log('going to set',data.s3)
                //  reset()
              } else {
                enqueueSnackbar("Niečo sa dosralo, chck console", {variant: `warning`, autoHideDuration: 6000});
                console.log(response.data)
              }
            } else if (response.data.error) {
              const {error} = response.data;
              enqueueSnackbar(`Chyba pri zmene s3 providera: ${error.title}`, {variant: `error`, autoHideDuration: 6000});
            }

          }))
          .catch(error => {
            console.log("any s3 provider change fetch api error");
            // console.log(error);
            // console.log(error.title);
            // enqueueSnackbar(`Chyba získavaní údajov o webe: ${error.error.title}`,{ variant: `error` });
            // setErr(error);
            // setIsAnalyzingAiImage(false)
            // return (final_user)

          })
      }
      isChangingS3Provider.onFalse()
    }

  }

  const handleChangeAllowUpload = (event) => {
    const allowUploadValue = event.target.value;
     console.log("Selected allow upload value:", allowUploadValue);
     console.log('tato fcia este nefunguje, resp. nevola sa na api')
    // axStore.setSystemCurrentConfig({ ...axStore.systemCurrentConfig, s3: response.data.data.ax_settings.s3 })
    // axStore.setSystemCurrentConfig({ ...axStore.systemCurrentConfig.s3, selected_s3: s3ProviderValue })

/*
  const jwt = localStorage.getItem('axjwt');
  if (jwt) {
    const postValues = new FormData();
    postValues.append('action', 'change_s3_provider');
    postValues.append('toS3', s3ProviderValue);

    axios.post('https://api.administrix.pro/v1/post/',
      postValues, {
        headers: {'Authorization': `Bearer ${jwt}`}
      })
      .then((response => {
        if (response.data.data) {
          const {data} = response.data;
          const {meta} = response.data;
          if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
          if (data.s3) {
            enqueueSnackbar("S3 providera sme úspešne zmenili.", {variant: `success`, autoHideDuration: 6000});
            axStore.setSystemCurrentConfig({ ...axStore.systemCurrentConfig, s3: data.s3 })
            setValue('s3_provider',data.s3.selected_s3)
            // console.log('going to set',data.s3)
            //  reset()
          } else {
            enqueueSnackbar("Niečo sa dosralo, chck console", {variant: `warning`, autoHideDuration: 6000});
            console.log(response.data)
          }
        } else if (response.data.error) {
          const {error} = response.data;
          enqueueSnackbar(`Chyba pri zmene s3 providera: ${error.title}`, {variant: `error`, autoHideDuration: 6000});
        }

      }))
      .catch(error => {
        console.log("any s3 provider change fetch api error");
        // console.log(error);
        // console.log(error.title);
        // enqueueSnackbar(`Chyba získavaní údajov o webe: ${error.error.title}`,{ variant: `error` });
        // setErr(error);
        // setIsAnalyzingAiImage(false)
        // return (final_user)

      })
  }
*/
  }





const watched_values = useWatch({ control,name:['payment_domain_count'] });


useEffect(()=> {
  // console.log("payment_is_active",getValues('payment_is_active'))
  // if(isPaymentActive)recalculatePaymentOverview()
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[watched_values])


  const processAiImage = (img_id) => {
    console.log('go to process ai analyze file with imgId',img_id )
    const jwt=localStorage.getItem('axjwt')
    if(jwt){
      setIsAnalyzingAiImage(true)
      let headers={}
      headers={
        'Authorization': `Bearer ${jwt}`
      }
      const uploadProcessData = new FormData();
      uploadProcessData.append('action', 'ai_analyze');
      uploadProcessData.append('imgid', img_id);

      axios.post(`https://api.administrix.pro/v1/post/index.php`, uploadProcessData, {
        headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${  jwt}`}
      })
        .then((response => {
           console.log(response)
          if(response.data.meta.jwt) localStorage.setItem('axjwt', response.data.meta.jwt);

          // setGalleries(response.data.data);
          setIsAnalyzingAiImage(false)
        }))
        .catch(error => {
          // console.log("any checkloginstatus fetch api error");
          // console.log(error);
          // setErr(error);
          setIsAnalyzingAiImage(false)
          // return (final_user)
        })


    }else{
      // console.log("jwt not found")
      // router.push('/',undefined,{ shallow: true })
      setIsAnalyzingAiImage(false)
    }

  };
  const [imgId, setimgId] = useState('');
  const handleButtonClick = () => {
    // Use imgId value here, for example, you can pass it to a function

    // processUploadedFilesQueues(imgId);
    processAiImage(imgId);
  };
  const handleTextFieldChange = (event) => {
    setimgId(event.target.value);
  };
  const getUsersForNewsletter = (level) => {
    // console.log("getFilesTags")
    const jwt = localStorage.getItem('axjwt')
    let headers = {}
    headers = {
      'Authorization': `Bearer ${jwt}`
    }

    axios.get('https://api.administrix.pro/v1/get/index.php', {
      headers,
      params: {
        'action': 'get_users_for_newsletter',
        'level': level,

      }
    })
      .then((response => {

        if (response.data.meta.jwt) localStorage.setItem('axjwt', response.data.meta.jwt);
        if (response.data.data.users) {
          setUsers(response.data.data.users)
        }
      }))
      .catch(error => {
        console.log("any get_users_for_newsletter fetch api error");

      })


  }
  const emailString = users.map(userItem => userItem.email_values).join(', ');
  const renderEditDepartment = (
    <>
      <DialogTitle>Nastavenie prostredia AdministriXu / piXme</DialogTitle>
      <DialogContent>
      <Grid container spacing={2} sx={{paddingTop:1}}>

      <Grid xs={12}>

      <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>

          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            aria-label="Nastavenia departmentu"
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label}/>
            ))}
          </Tabs>
        </Box>

        {currentTab === 'zakladne-nastavenia' &&
        <Grid xs={12} sx={{top: 0, overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}}>
          <Stack spacing={2}>



              <RHFSelect name="s3_provider" label="S3 Storage" onChange={handleChangeS3}>
                <MenuItem value="0" key="0" title="Chyba v systéme, z backendu sme nedostali správne s3 config dáta, pozri xhr request data" disabled>-nenastavene - chck title-</MenuItem>
                <MenuItem value="2" key="2">DigitalOcean Spaces</MenuItem>
                <MenuItem value="3" key="3">Hetzner S3</MenuItem>
                <MenuItem value="4" key="4">BackBlaze S3</MenuItem>
                <MenuItem value="6" key="6">Hetzner S3 + CDN</MenuItem>
                <MenuItem value="5" key="5">BackBlaze S3 + CDN</MenuItem>
              </RHFSelect>

              <RHFCheckbox name="allow_pixme_upload_globally" label="Povoľ upload v piXme" helperText="ESTE NEFUNGUJE - Ak je nejaká chyba systéme, môžeme globálne zakázať upload v piXme" onChange={handleChangeAllowUpload}/>


          </Stack>
        </Grid>}
        {currentTab === 'newsletter' &&
        <Grid xs={12} sx={{top: 0, overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}}>
          <Stack spacing={2}>
          <strong>Zoznam užívateľov:</strong>
            <Button onClick={()=>getUsersForNewsletter(1)}>show pixme users admins</Button>
            <Button onClick={()=>getUsersForNewsletter(2)}>show pixme users content moderator</Button>
            <Button onClick={()=>getUsersForNewsletter(0)}>show pixme users all</Button>

            <Typography variant="body1">
              {emailString}
            </Typography>
          </Stack>
        </Grid>}


        {currentTab === 'maintenance' &&
        <Grid xs={12} sx={{top: 0, overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}} container>

          <Grid xs={12} md={4} sx={{padding: 1}}>
            <Stack spacing={2}>
              <Typography variant="h5">
                Some maintenance
              </Typography>
              <Box sx={{display: {xs: 'none', sm: 'block'}}}/>

              <TextField
                name="img_id"
                label="Img id, which you want Ai process"
                size="small"
                value={imgId}
                onChange={handleTextFieldChange}
              />
              <Button onClick={handleButtonClick}>Ai Process</Button>
            </Stack>
          </Grid>

        </Grid>}


        {currentTab === 'db-ftp' &&
        <Grid xs={12} sx={{top: 0,overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}} container>

          <Grid xs={12} md={6}  lg={6} xl={4} sx={{padding: 1}}>
            <Stack spacing={2}>
              <Typography variant="h4">
                Nastavenia databázy
              </Typography>
                <RHFSwitch name="db_visible" label="Databáza je aktívna"/>
              {getValues('db_visible')&&(<>
              <RHFTextField name="db_server" label="DB Server" size="small"/>
              <RHFTextField name="db_user" label="DB User/login" size="small"/>
              <RHFTextField name="db_password" label="DB Password" size="small"/>
              <RHFTextField name="db_name" label="DB Name" size="small"/>
              <RHFTextField name="dsn_local" label="DSN LOCAL" size="small"/>
              <RHFTextField name="phpmyadmin" label="PHP MyAdmin" size="small"/>
              <RHFTextField name="db_port" label="DB Port" size="small"/>
              Pokročilejšie nastavenie:<br/>

                  Db server local napr. host=127.0.0.1;port=3306
                  <RHFTextField name="db_server_local" label="DB server local" size="small"/>
                  Db server unix socket napr. unix_socket=/var/run/mysqld/mysqld.sock
                  <RHFTextField name="db_server_unix_socket" label="DB server unix socket" size="small"/>
              </>
              )}
            </Stack>
          </Grid>
          <Grid xs={12} md={6} sx={{padding: 1}}>
            <Stack spacing={2}>
              <Typography variant="h4">
                Nastavenia ftp
              </Typography>
               <RHFSwitch name="ftp_visible" label="FTP je aktívne"/>
              {getValues('ftp_visible')&&(<>
              <RHFTextField name="ftp_server" label="FTP Server" size="small"/>
              <RHFTextField name="ftp_name" label="FTP login" size="small"/>
              <RHFTextField name="ftp_password" label="FTP password" size="small"/>
              <RHFTextField name="ftp_dir" label="FTP dir" size="small"/>
              </>)}
            </Stack>
          </Grid>

        </Grid>}



      </Box>


      </Grid></Grid>
      </DialogContent>

    </>
      )

  const onSubmit = handleSubmit(async (data) => {
    // enqueueSnackbar('Saving department data');
    // console.log('going to save department data',data)
    // postDepDataToSaveToServer(data)
    handleSubmitAxSetting(data)

  });


    const handleSubmitAxSetting = (ax_data_to_save) => {

      const jwt = localStorage.getItem('axjwt');
      if (jwt) {
        const postValues = new FormData();
        postValues.append('action', 'save_ax_setting');
        // postValues.append('allow_upload', getValues('allow_pixme_upload_globally'));
        Object.entries(ax_data_to_save).forEach(([key, value]) => {
          postValues.append(key, value);
        });

        axios.post('https://api.administrix.pro/v1/post/',
          postValues, {
            headers: {'Authorization': `Bearer ${jwt}`}
          })
          .then((response => {
            if (response.data.data) {
              const {data} = response.data;
              const {meta} = response.data;
              if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
              if (data.success) {
                enqueueSnackbar("Nastavenia sme úspešne aktualizovali.", {variant: `success`, autoHideDuration: 6000});
                axStore.setSystemCurrentConfig({
                  ...axStore.systemCurrentConfig,
                  main: {
                    ...axStore.systemCurrentConfig.main,
                    allow_upload: getValues('allow_pixme_upload_globally')==="false"?0:1
                  }
                });
        reset(ax_data_to_save)
              } else {
                enqueueSnackbar("Niečo sa dosralo, chck console", {variant: `warning`, autoHideDuration: 6000});
                 console.log(response.data)
              }
            } else if (response.data.error) {
              const {error} = response.data;
              enqueueSnackbar(`Chyba pri zmene nastavení: ${error.title}`, {variant: `error`, autoHideDuration: 6000});
            }

          }))
          .catch(error => {
            console.log("any system settings  change fetch api error");
             console.log(error);
            // console.log(error.title);
            // enqueueSnackbar(`Chyba získavaní údajov o webe: ${error.error.title}`,{ variant: `error` });
            // setErr(error);
            // setIsAnalyzingAiImage(false)
            // return (final_user)

          })
      }

  }

  return (


    <Dialog
      fullWidth
      maxWidth={dialogWidth}
      open={open}

    >
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmit, onError)}

      >
          {renderEditDepartment}

        <DialogActions>

          {(isDirty) && <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            loading={isSubmitting}

            startIcon={<SaveIcon/>}
            sx={{ml: 2, backgroundColor: '#cc0d0d', color: '#fff'}}
          >
            Ulož zmeny
          </LoadingButton>
          }

          <Button variant="outlined" onClick={onClose}>
            Zatvor
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>

  );
}

AxPixmeQuickEditForm.propTypes = {

  onClose: PropTypes.func,
  open: PropTypes.bool,

};
