// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// routes
import { paths } from 'src/routes/paths';
// locales
import BugReportIcon from '@mui/icons-material/BugReport';
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import {Tooltip} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import LinearProgress from "@mui/material/LinearProgress";
import {useAxStore} from "../../store/axStore";
import SendBugModal from "../../modal/send-bug-modal";
import {useBoolean} from "../../hooks/use-boolean";
import Logo from "../../components/logo";

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { user } = useMockedUser();

  const axStore = useAxStore();
  const [bugModalOpen,setBugModalOpen] = useState(false);
  const { t, onChangeLang } = useTranslation()



  return (

    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">








        <Logo sx={{ marginBottom: "20px",maxWidth:"40%" }} url={axStore.systemCurrentConfig.logo}/>
        <SendBugModal open={bugModalOpen} setOpen={setBugModalOpen} />
        <Tooltip title={t('bug1')}>
        <BugReportIcon sx={{fontSize:'2em',color:"#949494",cursor:"pointer"}} onClick={()=>setBugModalOpen(true)} />
        </Tooltip>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2,pl:2,pr:2 }}>


          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>

            <Tooltip title={`Deployment date: ${ axStore.systemCurrentConfig.systemDeployDate}`}><>{axStore.systemCurrentConfig.systemVersion}</></Tooltip>
          </Typography>
        </Stack>


      </Stack>
    </Stack>
  );
}
