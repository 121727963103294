
import PropTypes from 'prop-types';
import {React,useState, useRef, useCallback, useEffect} from 'react';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
// hooks
import axios from "axios";
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
//
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import {Grow} from "@mui/material";
import Typography from "@mui/material/Typography";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import FileManagerPanel from './file-manager-panel';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";

import FileManagerFolderItem from './file-manager-folder-item';
import FileManagerActionSelected from './file-manager-action-selected';
import FileManagerShareDialog from './file-manager-share-dialog';
import FileManagerNewFolderDialog from './file-manager-new-folder-dialog';
import {useSettingsContext} from "../../components/settings";

import PixmeFileDetailsDrawer from "./pixmeFIleDetailsDrawer";
import PixmeFileItem from "./pixme-file-item";
// import Lightbox, {useLightBox} from "../../components/lightbox";
import {useAxStore} from "../../store/axStore";
// eslint-disable-next-line import/order
import {enqueueSnackbar} from "notistack";
import {ConfirmDialog} from "../../components/custom-dialog";
import {fileFormat, fileThumb} from "../../components/file-thumbnail";
import ModalLightboxForFilePreview from "../../modal/modalLightboxForFilePreview";


// import PixmeShowSelectedModal from "../../modal/pixShowSelectedModal";

// ----------------------------------------------------------------------

export default function PixmeFilesGridView({
  searchedValue,
  data,
  fid=0,
  searchMeta=null,
  showCount=true,
  showRelevanceSegments=true,
  wholeCount,
  small=false,
  allowSetTitle=false,
  allowDelete=false

}) {
  // const { selected, onSelectRow: onSelectItem, onSelectAllRows: onSelectAllItems } = table;

  const {
    selectedFiles,
    selectedFiles_insert,

    selectedFiles_insert_multiple,
    selectedFiles_delete,
    selectedFiles_deleteAll,
    selectedFiles_exists
  } = useAxStore()

  const containerRef = useRef(null);

  const [folderName, setFolderName] = useState('');

  const [inviteEmail, setInviteEmail] = useState('');
  const { t, onChangeLang } = useTranslation()


  // const lightbox = useBoolean(false);



  const upload = useBoolean();
  const confirmDelete = useBoolean();
  const files = useBoolean();
  const axStore=useAxStore();

  const folders = useBoolean();
  const settings = useSettingsContext();
   // const lightbox = useLightBox();
  const handleChangeInvite = useCallback((event) => {
    setInviteEmail(event.target.value);
  }, []);

  const handleChangeFolderName = useCallback((event) => {
    setFolderName(event.target.value);
  }, []);


  const [slides, setSlides] = useState([]);
  const [curindex, setIndex] = useState(-1);
  const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'webm', 'flv', 'wmv', 'm4v'];

  const getExtension = (filename) => {
    const parts = filename.split('.');
    return parts.length > 1 ? parts.pop() : '';
  };
  const removeExtension = (filename) => filename.replace(/\.[^/.]+$/, "");
  useEffect(() => {
    // console.log("data:",data);

    if (data && data.length > 0) {
      const formattedSlides = data.map(item => {
        const isVideo = videoExtensions.some(ext =>
          item.filename.toLowerCase().endsWith(`.${ext}`)
        );

        const srcPath =
          // eslint-disable-next-line no-nested-ternary
          item.res_thumb !== "0" && item.res_thumb !== ""
            ? item.is_picture === "0"
              ? `${axStore.systemCurrentConfig.s3.s3_endpoint_preview}${removeExtension(item.filename)}.jpg`
              : `${axStore.systemCurrentConfig.s3.s3_endpoint_preview}${item.filename}`
            : fileThumb(getExtension(item.filename));

        if (isVideo) {
          return {
            type: "video",
            sources: [{ src: srcPath,id:item.fileid }],
          };
        }

        return { src: srcPath,id:item.fileid };
      });

      setSlides(formattedSlides);

      // console.log('formattedSlides:',formattedSlides)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  const details = useBoolean();


  const [detailedFile,setDetailedFile]=useState(null)
  const [fileToDelete,setFileToDelete]=useState(null)



  const handleDeleteItem = (item) => {
    setFileToDelete(item)
    confirmDelete.onTrue()

  };

  function isImage(title_photo_filename) {
    if (!title_photo_filename || typeof title_photo_filename !== 'string') {
      return false;
    }
    const imagePattern = /\.(jpg|jpeg|png|gif|bmp|svg|webp|tiff)$/i;
    return imagePattern.test(title_photo_filename);
  }

  const deleteFile = async (fileid) => {
    // console.log(`going to delete ${fileid}`)

    const jwt = localStorage.getItem('axjwt');
    if (jwt) {
      try {
        const postData = new FormData();
        postData.append('action', 'delete_pixme_files');
        postData.append('files', [fileid]);
        postData.append('only_from_folder', fid);

        const response = await axios.post('https://api.administrix.pro/v1/post/', postData, {
          headers: { 'Authorization': `Bearer ${jwt}` }
        });

        const responseData = response.data;
        const { meta } = responseData;

        if (meta?.jwt) localStorage.setItem('axjwt', meta.jwt);

        if (responseData.data.success) {
          enqueueSnackbar(t('pxfi.file_successfully_deleted'), { variant: 'success', autoHideDuration: 6000 });
          axStore.setNeedReloadCurrentFolder(true)
        } else {
          enqueueSnackbar(t('pxfi.file_deletion_failed'), { variant: 'error', autoHideDuration: 6000 });
          axStore.setNeedReloadCurrentFolder(true)
        }
      } catch (error) {
        if (error.response) {
          console.error("Server error:", error.response);
          enqueueSnackbar(`Error: ${error.response.data.error.title || 'Unknown'}`, { variant: 'error', autoHideDuration: 6000 });
          axStore.setNeedReloadCurrentFolder(true)
        } else {
          console.error("Request error:", error.message);
          enqueueSnackbar("Network or API error.", { variant: 'error', autoHideDuration: 6000 });
          axStore.setNeedReloadCurrentFolder(true)
        }
      }
    }
  };

  const setAsTitle = async (fileid) => {
    const jwt = localStorage.getItem('axjwt');
    if (jwt) {
      try {
        const postData = new FormData();
        postData.append('action', 'set_file_as_pixme_folder_title');
        postData.append('fileid', fileid);
        postData.append('folderid', fid);

        const response = await axios.post('https://api.administrix.pro/v1/post/', postData, {
          headers: { 'Authorization': `Bearer ${jwt}` }
        });

        const responseData = response.data;
        const { meta } = responseData;

        if (meta?.jwt) localStorage.setItem('axjwt', meta.jwt);

        if (responseData.data.success) {
          enqueueSnackbar(t('pxfi.title_set_succes'), { variant: 'success', autoHideDuration: 6000 });
          console.log('ok:',responseData.data)
        } else {
          enqueueSnackbar(t('pxfi.title_set_failed'), { variant: 'error', autoHideDuration: 6000 });
          console.log('fail:',responseData.data)
        }
      } catch (error) {
        if (error.response) {
          console.error("Server error:", error.response);
          enqueueSnackbar(`Error: ${error.response.data.error.title || 'Unknown'}`, { variant: 'error', autoHideDuration: 6000 });
        } else {
          console.error("Request error:", error.message);
          enqueueSnackbar("Network or API error.", { variant: 'error', autoHideDuration: 6000 });
        }
      }
    }
  };



  const handleShowPreview= (idx) => {
    // lightbox.onOpen(preview.filename)
    // console.log('show preview',idx)
     setIndex(idx)

    // lightbox.onOpen(5)

  }
  const handleSelect = (fileId) => {
    if (selectedFiles_exists(fileId)) {
      selectedFiles_delete(fileId);
    } else {
      selectedFiles_insert(fileId);
    }
  };

  const groupedData = data.reduce((groups, item) => {
    if (!groups[item.query_index]) {
      groups[item.query_index] = [];
    }
    groups[item.query_index].push(item);
    return groups;
  }, {});

  /* const handlePreviewItem = useCallback(
    (idx) => {
      setIndex(idx); // Assuming `setIndex` is a state setter
      // handleShowPreview(idx); // Call handleShowPreview as needed
    },
    [setIndex] // Dependencies for memoization
  ); */

  return (
    <>
      <PixmeFileDetailsDrawer
        item={detailedFile}
        favorited={upload.value} // upload is insadne but temporary
        onFavorite={upload.onToggle}
        // onCopyLink={handleCopy}
        open={details.value}
        onClose={details.onFalse}
        onDelete={() => {
          details.onFalse();
           handleDeleteItem(detailedFile);
        }}
      />
      {searchedValue&&<h1 style={{textAlign:"center",fontSize:"1.2em"}}>{t('search_result_for')} <span style={{color:"#ff2727"}}>{searchedValue}</span></h1>}

      {(showCount&&data.length>0)&&
      <div style={{textAlign: "center",fontSize:"0.9em",marginBottom:"20px"}}>{`${t('showing')} ${data.filter((item) => item.type !== 'folder').length} ${t('files_gen')}
      `} {(wholeCount>0)&&<>{t('of')} {wholeCount}</>}</div>
      }


      {searchMeta&&!data.some(item => item.relevance === 1)&&
          <Typography variant="h6" sx={{textAlign:"center",margin:"20px"}}>{t('pxs.didnt_found_you_were_looking_for')}
            <span style={{color:"#d50857"}}> {searchMeta.performed_queries[1].tags_to_search}
              </span>
            </Typography>
      }


        {
          showRelevanceSegments?Object.entries(groupedData).map(([query_index, items],index_group) =>{

           // console.log(index_group)
           // console.log(groupedData)

          const previousGroupsLength = Object.entries(groupedData)
            .slice(0, index_group) // Get all previous groups before the current one
            .reduce((sum, [, group]) => sum + group.length, 0); // Sum the lengths of previous groups





          return(
          <div key={query_index}>

            {searchMeta&&
            <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{textAlign: "center", margin: "20px"}}>
                {
                  (searchMeta.performed_queries[query_index].relevance===1)&&
                  t('pxs.results_for')
                }
                {
                  (searchMeta.performed_queries[query_index].relevance>1&&index_group<2)&&
                    t('pxs.you_may_be_interested')
                }
                {
                    (searchMeta.performed_queries[query_index].relevance>1&&index_group>1)&&
                    t('pxs.you_may_be_interested2')
                }

                &nbsp;<span style={{color: "#d50857"}}>
                {searchMeta.performed_queries[query_index]?.tags_to_search}
                </span>
              </Typography>
            </Grid>
            </Grid>
            }



            <Grid container xs={12} sx={{justifyContent:"center"}}  gap={2}>

            {items
              .filter((i) => i.type !== 'folder')

              .map((file, index, array) =>{

                  /* console.log('fileCounter:')
                  console.log(fileCounter)
                  console.log('index:')
                  console.log(index) */
                  const globalIndex = previousGroupsLength + index;
                return(
                  <div key={file.fileid}>
                    <Grow
                      in
                      timeout={ 500 } // Only apply delay to enter animation

                      key={index}
                      // Apply a staggered delay based on the index
                      onEntering={(node) => {
                        node.style.transitionDelay = `${index * 20}ms`; // Stagger the animation
                      }}
                    >
                      <Grid >

                        <PixmeFileItem
                          file={file}
                          setAsTitle={allowSetTitle&&isImage(file.filename)?setAsTitle:null}
                          onOpenDetail={() => {
                            setDetailedFile(file);
                            details.onTrue();
                          }}
                          small={small}

                          selected={selectedFiles.includes(file.fileid)}
                          onSelect={() =>   handleSelect(file.fileid)}

                          onDelete={allowDelete ? () => handleDeleteItem(file) : undefined}

                           onShowPreview={() => handleShowPreview(globalIndex)}
                          sx={{
                            width: small ? '150px' : '250px',
                          }}
                        />


                      </Grid>
                    </Grow>
                  </div>
                )
            }
              )}
            </Grid>
          </div>
        )}):(<Grid container xs={12} sx={{justifyContent:"center"}}  gap={2}>
              {
            Object.entries(groupedData).map(([query_index, items],index_group) =>{
              const previousGroupsLength = Object.entries(groupedData)
                .slice(0, index_group) // Get all previous groups before the current one
                .reduce((sum, [, group]) => sum + group.length, 0); // Sum the lengths of previous groups
              return(
                <>
                    {items
                      .filter((i) => i.type !== 'folder')

                      .map((file, index, array) =>{

                          /* console.log('fileCounter:')
                          console.log(fileCounter)
                          console.log('index:')
                          console.log(index) */
                          const globalIndex = previousGroupsLength + index;
                          return(

                              <Grow
                                in
                                timeout={ 500 } // Only apply delay to enter animation

                                key={file.fileid}
                                // Apply a staggered delay based on the index
                                onEntering={(node) => {
                                  node.style.transitionDelay = `${index * 20}ms`; // Stagger the animation
                                }}
                              >
                                <Grid >

                                  <PixmeFileItem
                                    file={file}
                                    onOpenDetail={() => {
                                      setDetailedFile(file);
                                      details.onTrue();
                                    }}
                                    setAsTitle={allowSetTitle&&isImage(file.filename)?setAsTitle:null}
                                    small={small}
                                    selected={selectedFiles.includes(file.fileid)}
                                    onSelect={() =>   handleSelect(file.fileid)}
                                    onDelete={() => handleDeleteItem(file)}
                                    onShowPreview={() => handleShowPreview(globalIndex)}
                                    // onShowPreview={() => handlePreviewItem(index)}
                                    sx={{
                                      width: small ? '150px' : '250px',
                                    }}
                                  />


                                </Grid>
                              </Grow>

                          )
                        }
                      )}
                </>
              )})
              }
      </Grid>)



        }









      <ConfirmDialog
        open={confirmDelete.value}
        onClose={confirmDelete.onFalse}
        title={t('pxfi.delete_file')}

        content={`${t('pxfi.delete_file')} ${fileToDelete?.original_filename} ?`}
        action={
          <Button variant="contained" color="error" onClick={()=>{confirmDelete.onFalse();deleteFile(fileToDelete?.fileid)}}>
            {t('pxfi.delete_file')}
          </Button>
        }
        cancelCaption={t('close')}
      />
      <ModalLightboxForFilePreview slides={slides} curindex={curindex} setIndex={setIndex} />
      {/*
      <Lightbox
        plugins={[Video,Slideshow,Counter,Fullscreen]}
        slides={slides}
        open={curindex >= 0}
        index={curindex}
        close={() => setIndex(-1)}
        disabledThumbnails
        animation={{ fade: true }}
      />
      */ }

      {/*
       <Lightbox
        slides={slides}
        open={curindex >= 0}
        index={curindex}
        close={() => setIndex(-1)}
        disabledThumbnails

      /> */}

    </>
  );
}

PixmeFilesGridView.propTypes = {
  data: PropTypes.array,
  searchMeta: PropTypes.object,
  searchedValue: PropTypes.string,
  wholeCount: PropTypes.number,
  fid: PropTypes.number,
  showCount: PropTypes.bool,
  allowSetTitle: PropTypes.bool,
  showRelevanceSegments: PropTypes.bool,// if true, show segments of relevance
  small: PropTypes.bool,
  allowDelete: PropTypes.bool,

};
