import PropTypes from 'prop-types';
import {useState, useCallback, useEffect} from 'react';
// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AvatarGroup, { avatarGroupClasses } from '@mui/material/AvatarGroup';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
// utils
import { fDateTime } from 'src/utils/format-time';
import { fData } from 'src/utils/format-number';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import CropOriginalIcon from '@mui/icons-material/CropOriginal';
// components
import Iconify from 'src/components/iconify';
import DownloadIcon from '@mui/icons-material/Download';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useSnackbar } from 'src/components/snackbar';
import TextMaxLine from 'src/components/text-max-line';
import FileThumbnail, {fileThumb} from 'src/components/file-thumbnail';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import {Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FileManagerShareDialog from './file-manager-share-dialog';

import axios from "../../utils/axios";
import Image from "../../components/image";
import {useAxStore} from "../../store/axStore";

// ----------------------------------------------------------------------

export default function PixmeFileItem({ file, selected, onSelect, onDelete, sx,onOpenDetail,onShowPreview,small,setAsTitle,lookInFolders=false,  ...other }) {
  const { enqueueSnackbar } = useSnackbar();

  const { copy } = useCopyToClipboard();

  const [inviteEmail, setInviteEmail] = useState('');


  const checkbox = useBoolean();

  const share = useBoolean();



  const axStore=useAxStore()
  const user=axStore.axCurrentUser;
  const { t, onChangeLang } = useTranslation()
  const favorite = useBoolean(file.isFavorited);

  const popover = usePopover();
  const actionPopover = usePopover();


  const handleChangeInvite = useCallback((event) => {
    setInviteEmail(event.target.value);
  }, []);

  const handleCopy = useCallback(() => {
    enqueueSnackbar('Copied!');
    copy(file.url);
  }, [copy, enqueueSnackbar, file.url]);

  useEffect(() => {
// console.log('ondelete')
// console.log(onDelete)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function forceDownload(imageSrc, nameOfDownload = 'my-image.png') {
    try {
      // Fetch the image
      const response = await fetch(imageSrc);
      const blobImage = await response.blob();

      // Create a URL for the image
      const imageUrl = URL.createObjectURL(blobImage);

      // Create an anchor element
      const anchorElement = document.createElement('a');
      anchorElement.href = imageUrl;
      anchorElement.download = nameOfDownload;

      // Append the anchor element to the DOM
      document.body.appendChild(anchorElement);

      // Trigger a click event to initiate the download
      anchorElement.click();
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  }

  function handleDownload(fileid,filesize) {
    //  filesie: 1=preview, 2=1318, 3=a4, 4=full
    enqueueSnackbar(`Downloading ...`);
      let headers={}
      const jwt = localStorage.getItem('axjwt');
      if(jwt) {
        headers = {
          'Authorization': `Bearer ${jwt}`
        }
        const params = {
          'action': 'get_pixme_file_download_link',
          'fileid': fileid,
          'dwnld_size': filesize
        }
        axios.get('https://api.administrix.pro/v1/get/', {
          headers,
          params
        })
          .then((response => {
            if (response.data.data) {
              const {data} = response.data;
              const {meta} = response.data;
              if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
              if (data.file_link) {
                forceDownload(data.file_link,data.file_name)
              }
            }else
            if (response.data.error) {
              const {error} = response.data;
               enqueueSnackbar(`Chyba pri sťahovaní súboru:  ${error.title}`,{ variant: `error` });
            }



          }))
          .catch(error => {
            enqueueSnackbar(`fckn error ...`);
            // console.log("any force_select_department fetch api error");
            // console.log(error.title);

            // setErr(error);
            // setIsUploadedFilesQueueProcessing(false)
            // return (final_user)
          })
      }








    // py(file.url);
  };


    const fileSize = (bytes) => {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return `${parseFloat((bytes / k**i).toFixed(2))  } ${  sizes[i]}`;
    };


 /* const renderIcon =
    (checkbox.value || selected) && onSelect ? (
      <Checkbox
        size="medium"
        checked={selected}
        onClick={onSelect}
        icon={<Iconify icon="eva:radio-button-off-fill" />}
        checkedIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}
        sx={{ p: 0.75 }}
      />
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <></>
  /* <FileThumbnail file={file} sx={{ width: 56, height: 36 }} /> */
/*
    ); */

  const renderAction = (
    <Stack direction="row" alignItems="center" >
      {/*
      <Checkbox
        color="warning"
        icon={<Iconify icon="eva:star-outline" />}
        checkedIcon={<Iconify icon="eva:star-fill" />}
        checked={favorite.value}
        onChange={favorite.onToggle}
      /> */}

      <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
        <Tooltip title={t('download1')}>
        <Iconify icon="material-symbols:download" />
        </Tooltip>
      </IconButton>


      <IconButton  onClick={onOpenDetail}>
        <Tooltip title={t('file_information')}>
        <Iconify icon="material-symbols:info-outline" />
        </Tooltip>
      </IconButton>

      <Checkbox
        size="medium"
        checked={selected}
        onClick={onSelect}
        icon={<Iconify icon="eva:radio-button-off-fill" />}
        checkedIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}
        sx={{ p: 0.75 }}
      />


      {user.ax_role<1&&user.department_role<2&&onDelete&&
        <IconButton  onClick={onDelete} color="error">
          <Tooltip title={t('pxfi.delete_file')}>
            <DeleteOutlineIcon/>
          </Tooltip>
        </IconButton>
        }

      {setAsTitle&&
      <IconButton color={actionPopover.open ? 'inherit' : 'default'} onClick={actionPopover.onOpen}>
        <Tooltip title="more...">
          <MoreVertIcon/>
        </Tooltip>
      </IconButton>
      }
    </Stack>
  );

  const renderText = (
    <>
      <TextMaxLine
        persistent
        variant="subtitle2"
        onClick={onOpenDetail}
        sx={{ width: 1, textAlign:"center" }}
      >
        {file.original_filename}
      </TextMaxLine>

      {/* <Stack
        direction="row"
        alignItems="center"
        sx={{
          maxWidth: 0.99,
          whiteSpace: 'nowrap',
          typography: 'caption',
          color: 'text.disabled',
        }}
      >
        {fData(file.size)}

        <Box
          component="span"
          sx={{
            mx: 0.75,
            width: 2,
            height: 2,
            flexShrink: 0,
            borderRadius: '50%',
            bgcolor: 'currentColor',
          }}
        />
        <Typography noWrap component="span" variant="caption">
          {fDateTime(file.modifiedAt)}
        </Typography>
      </Stack> */}
    </>
  );

  const renderAvatar = (
    <AvatarGroup
      max={3}
      sx={{
        mt: 1,
        [`& .${avatarGroupClasses.avatar}`]: {
          width: 24,
          height: 24,
          '&:first-of-type': {
            fontSize: 12,
          },
        },
      }}
    >
      {file.shared?.map((person) => (
        <Avatar key={person.id} alt={person.name} src={person.avatarUrl} />
      ))}
    </AvatarGroup>
  );
  const getExtension = (filename) => {
    const parts = filename.split('.');
    return parts.length > 1 ? parts.pop() : '';
  };
  const removeExtension = (filename) => filename.replace(/\.[^/.]+$/, "");
  return (
    <>
      <Stack
        component={Paper}
        variant="outlined"
        alignItems="flex-start"
        sx={{
          p: 0.5,
          borderRadius: 2,
          bgcolor: 'unset',
          cursor: 'pointer',
          position: 'relative',
          ...((checkbox.value || selected) && {
            bgcolor: 'background.paper',
            boxShadow: (theme) => theme.customShadows.z20,
          }),
          ...sx,
        }}
        {...other}
      >
        {/*
        <Box onMouseEnter={checkbox.onTrue} onMouseLeave={checkbox.onFalse}>
          {renderIcon}
        </Box> */}
        {/*          <FileThumbnail file={file} sx={{ width: 56, height: 36 }} /> */}
        {/*
        <Image  ratio="1/1" src={(file.res_thumb !== "0" && file.res_thumb !== "")?`${axStore.systemCurrentConfig.s3.s3_endpoint_preview}t_${file.filename}`:fileThumb(getExtension(file.filename))}  onClick={onShowPreview} sx={{maxWidth:"100%",borderRadius: 2}} title={file.original_filename}/>
*/}


        <Image
          ratio="1/1"
          src={
            // eslint-disable-next-line no-nested-ternary
            file.res_thumb !== "0" && file.res_thumb !== ""
              ? file.is_picture === "0"
                ? `${axStore.systemCurrentConfig.s3.s3_endpoint_preview}t_${removeExtension(file.filename)}.jpg`
                : `${axStore.systemCurrentConfig.s3.s3_endpoint_preview}t_${file.filename}`
              : fileThumb(getExtension(file.filename))
          }
          onClick={onShowPreview}
          sx={{ maxWidth: "100%", borderRadius: 2 }}
          title={file.original_filename}
        />




        {/* (file.ai_search&&file.ai_search===1)&&
        <Image  ratio="1/1" src={`${axStore.systemCurrentConfig.s3.s3_endpoint_system}system/ai_label.png`} sx={{ position:"absolute",maxWidth:"28px",top:"12px",right:"12px" }} title="Found in AI tags"/> */}

        {!small&&renderText}

        {/* renderAvatar */}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: '100%',  padding: 0 }}
        >
        {renderAction}
        </Stack>
      </Stack>

      {setAsTitle&&
      <CustomPopover
        open={actionPopover.open}
        onClose={actionPopover.onClose}
        arrow="bottom"
        sx={{ width: 300 }}
      >

        {setAsTitle&&
        <MenuItem
          onClick={() => {
            setAsTitle(file.fileid)
            actionPopover.onClose();
          }}
        >
            <CropOriginalIcon/>{t('pxfi.set_as_title_photo')}
        </MenuItem>
        }


        {user.ax_role<1&&
        <MenuItem
          onClick={() => {
            actionPopover.onClose();
            setAsTitle();
          }}
        >
            <PermMediaIcon/>{t('pxfi.file_occurence')}
        </MenuItem>
      }
      </CustomPopover>
      }
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        { /*
        <MenuItem
          onClick={() => {
            popover.onClose();
            handleCopy();
          }}
        >
          <Iconify icon="eva:link-2-fill" />
          Copy Link
        </MenuItem>
*/}

        {/* (parseInt(file.filesize_preview,10)>0)&&
        <MenuItem
          onClick={() => {
            popover.onClose();
            handleDownload(file.fileid,1);
          }}
          title={fileSize(file.filesize_preview)}
        >
          <DownloadIcon/>
          {file.res_preview}
        </MenuItem> */}
        {
          ((file.filesize_1318==="0")&&
          (file.filesize_a4==="0")&&
          (file.filesize_full==="0"))&&
          <MenuItem
            onClick={() => {
              popover.onClose();
              handleDownload(file.fileid,1);
            }}
            title={fileSize(file.filesize)}
          >
            <DownloadIcon/>
            Download file...
          </MenuItem>
        }
        {(parseInt(file.filesize_1318,10)>0)&&
        <MenuItem
          onClick={() => {
            popover.onClose();
            handleDownload(file.fileid,2);
          }}
          title={fileSize(file.filesize_1318)}
        >
          <DownloadIcon/>
          {file.res_1318}
        </MenuItem>}

        {(parseInt(file.filesize_a4,10)>0)&&
        <MenuItem
          onClick={() => {
            popover.onClose();
            handleDownload(file.fileid,3);
          }}
          title={fileSize(file.filesize_a4)}
        >
          <DownloadIcon/>
          {file.res_a4}
        </MenuItem>}

      {(parseInt(file.filesize_full,10)>0)&&
        <MenuItem
          onClick={() => {
            popover.onClose();
            handleDownload(file.fileid,4);
          }}
          title={fileSize(file.filesize_full)}
        >
          <DownloadIcon/>
          {file.res_full}
        </MenuItem>}

        {/* <MenuItem
          onClick={() => {
            popover.onClose();
            share.onTrue();
          }}
        >
          <Iconify icon="solar:share-bold" />
          Share
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
        */}

      </CustomPopover>



      <FileManagerShareDialog
        open={share.value}
        shared={file.shared}
        inviteEmail={inviteEmail}
        onChangeInvite={handleChangeInvite}
        onCopyLink={handleCopy}
        onClose={() => {
          share.onFalse();
          setInviteEmail('');
        }}
      />


    </>
  );
}

PixmeFileItem.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onDelete: PropTypes.func,
  onOpenDetail: PropTypes.func,
  onDeleteFile: PropTypes.func,
  onShowPreview: PropTypes.func,
  setAsTitle: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  lookInFolders: PropTypes.bool,
  small: PropTypes.bool,
  sx: PropTypes.object,
};
