import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  Badge,
  Tooltip
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Upload as UploadIcon,
  ImportExport as ToggleIcon
} from '@mui/icons-material';
import ShareIcon from '@mui/icons-material/Share';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useAxStore } from "../../store/axStore";
import ShareGalleryModal from "../pixme-modal/pixmeShareGalleryModal";
import Iconify from "../iconify";

export default function SelectedFilesFloatingActionBar({ selectedFiles }) {
  const isMobileOrTablet = useMediaQuery('(max-width:768px)');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isBarOnBottom, setIsBarOnBottom] = useState(true);
  const axStore = useAxStore();
  const user = axStore.axCurrentUser;
  const { t } = useTranslation();
  const [isShareModalOpen, setShareModalOpen] = useState(false);


  const actions = [
    {
      name: 'send_to_download',
      action: () => alert('Upload files'),
      icon: <UploadIcon />,
      color: 'primary',
      role: 3,
      showOnBar: true,
      showOnBarMobile: false,
    },
    {
      name: 'share_as_gallery',
      action: () => setShareModalOpen(true),
      icon: <ShareIcon />,
      color: 'primary',
      role: 3,
      showOnBar: true,
      showOnBarMobile: true,
    },
    {
      name: 'create_folder_from_files',
      action: () => alert('Create folder from files'),
      icon: <UploadIcon />,
      color: 'primary',
      role: 3,
      showOnBar: true,
      showOnBarMobile: false,
    },
    {
      name: 'move_files_to_folder',
      action: () => alert('Move files to folder'),
      icon: <UploadIcon />,
      color: 'primary',
      role: 3,
      showOnBar: false,
      showOnBarMobile: false,
    },
    {
      name: 'delete_from_pixme',
      action: () => alert('Delete from Pixme'),
      icon: null,
      color: 'secondary',
      role: 3,
      showOnBar: true,
      showOnBarMobile: false,
    },
    {
      name: 'show_selected_files',
      action: () => alert('show selected'),
      icon: <Iconify icon="ic:round-remove-done"/>,
      color: 'secondary',
      role: 3,
      showOnBar: false,
      showOnBarMobile: false,
    },
    {
      name: 'Deselect',
      action: () => axStore.selectedFiles_deleteAll(),
      icon: <RemoveDoneIcon />,
      color: 'secondary',
      role: 3,
      showOnBar: true,
      showOnBarMobile: false,
    }
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenShare = () => setShareModalOpen(true);

  const handleCloseShare = () => setShareModalOpen(false);

  const handleShareSubmit = (values) => {
    console.log("Submitted values:", values);
    handleCloseShare();
  };

  const toggleBarPosition = () => {
    setIsBarOnBottom(!isBarOnBottom);
  };

  // Effect to adjust body padding to prevent content shift when modal is open
  useEffect(() => {
    if (isShareModalOpen) {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }
    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
  }, [isShareModalOpen]);

  const barPositionStyles = isBarOnBottom ? {
    bottom: '30px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'calc(100% - 40px)',
    maxWidth: '1200px',
  } : {
    bottom: '30px',
    right: '30px',
    width: '60px',
    padding: '16px 0',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  return (
    <>
      <ShareGalleryModal
        open={isShareModalOpen}
        onClose={handleCloseShare}
        onSubmit={handleShareSubmit}
        defaultValues={{
          sharingName: '',
          allwDwnldFiles: false,
          dwnld1318: false,
          dwnldA4: false,
          dwnldFull: false,
          sharingExpiration: '30'
        }}
      />
      {selectedFiles.length > 0 && (
        <Box
          sx={{
            position: 'fixed',
            backgroundColor: "#c20e31",
            color: "white",
            boxShadow: 3,
            borderRadius: '16px',
            p: 2,
            zIndex: 1000,
            opacity: 0.9,
            ...barPositionStyles
          }}
        >
          {isBarOnBottom && (
            <Typography variant="body1" sx={{textAlign:"center"}}>
              {selectedFiles.length} file(s) selected
            </Typography>
          )}
          {!isBarOnBottom && (
            <Badge badgeContent={selectedFiles.length} color="error" sx={{ mb: 2 }}>
              <Tooltip title="Selected Files">
                <IconButton>
                  <TaskAltIcon style={{ color: "white" }} />
                </IconButton>
              </Tooltip>
            </Badge>
          )}
          <Box sx={{ display: 'flex', flexDirection: isBarOnBottom ? 'row' : 'column', alignItems: 'center', justifyContent: isBarOnBottom ? 'space-between' : 'flex-end', flexGrow: 1 }}>
            {isBarOnBottom ? (
              actions.map((action, index) =>
                  (user.department_role <= action.role || user.ax_role < 2) && action.showOnBar && (
                    <Button
                      key={index}
                      variant="contained"
                      color={action.color}
                      sx={{ mx: 1 }}
                      onClick={action.action}
                      startIcon={action.icon}
                    >
                      {t(`fltslct.${action.name}`)}
                    </Button>
                  )
              )
            ) : (
              actions.reverse().map((action, index) =>
                  (user.department_role <= action.role || user.ax_role < 2) && action.showOnBar && (
                    <Tooltip title={t(`fltslct.${action.name}`)} key={index}>
                      <IconButton color={action.color} onClick={action.action} sx={{ mb: 1 }}>
                        {action.icon}
                      </IconButton>
                    </Tooltip>
                  )
              )
            )}
            <Tooltip title="Toggle Bar Position">
              <IconButton color="primary" onClick={toggleBarPosition} sx={{ mt: 1 }}>
                <SwapHorizIcon style={{ color: "white" }} />
              </IconButton>
            </Tooltip>
            <IconButton aria-controls="actions-menu" aria-haspopup="true" onClick={handleClick} sx={{ mt: 1 }}>
              <MoreVertIcon style={{ color: "white" }} />
            </IconButton>
          </Box>
          <Menu
            id="actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            {actions.map((action, index) => (
              (user.department_role <= action.role || user.ax_role < 2) && (
                <MenuItem
                  key={index}
                  onClick={() => {
                    action.action();
                    handleClose();
                  }}
                >
                  {action.icon}{t(`fltslct.${action.name}`)}
                </MenuItem>
              )
            ))}
          </Menu>
        </Box>
      )}
    </>
  );
}

SelectedFilesFloatingActionBar.propTypes = {
  selectedFiles: PropTypes.array,
};
