import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
//
import {useEffect} from "react";
import Iconify from '../iconify';

// ----------------------------------------------------------------------

export default function UploadBox({ filesToUpload,setFilesToUpload, placeholder, error, disabled, sx, ...other }) {
  const { getRootProps, getInputProps, isDragActive,isDragReject } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    maxFiles: 10,
    multiple: true,
    onDrop: (acceptedFiles) => {
      console.log(`ondrop: ${acceptedFiles.length}`);
      console.log(acceptedFiles);

      setFilesToUpload(
        acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
    },
    disabled,
    ...other,
  });

// Cleanup to prevent memory leaks
  useEffect(() => () => {
      filesToUpload.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [filesToUpload]);


  const hasError = isDragReject || error;

  return (
    <Box
      {...getRootProps()}
      sx={{
        m: 0.5,
        width: 64,
        height: 64,
        flexShrink: 0,
        display: 'flex',
        borderRadius: 1,
        cursor: 'pointer',
        alignItems: 'center',
        color: 'text.disabled',
        justifyContent: 'center',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
        border: (theme) => `dashed 1px ${alpha(theme.palette.grey[500], 0.16)}`,
        ...(isDragActive && {
          opacity: 0.72,
        }),
        ...(disabled && {
          opacity: 0.48,
          pointerEvents: 'none',
        }),
        ...(hasError && {
          color: 'error.main',
          bgcolor: 'error.lighter',
          borderColor: 'error.light',
        }),
        '&:hover': {
          opacity: 0.72,
        },
        ...sx,
      }}
    >
      <input {...getInputProps()} />

      {placeholder || <Iconify icon="eva:cloud-upload-fill" width={28} />}
    </Box>
  );
}

UploadBox.propTypes = {
  disabled: PropTypes.object,
  error: PropTypes.bool,
  placeholder: PropTypes.object,
  sx: PropTypes.object,
  setFilesToUpload: PropTypes.func,
  filesToUpload: PropTypes.array,
};
