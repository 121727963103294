// https://codesandbox.io/s/zme38  //ako generovat nahlad z accepted files
// https://www.freakyjolly.com/react-upload-files-using-react-dropzone/
// https://upmostly.com/tutorials/react-dropzone-file-uploads-react
// tato komponenta je zalozena na klasickom dropzone. presiel som na iny dropzone z dovodu progeress baru a preview
// npm install --save react-dropzone
// npm uninstall --save react-dropzone

import React,{useCallback,useState} from 'react';
import {useDropzone} from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import axios from "axios";
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import {useTranslation} from "react-i18next";
import FileThumbnail from "../file-thumbnail";
import uuidv4 from "../../utils/uuidv4";
import {UploadIllustration} from "../../assets/illustrations";
// import {useUploadStore} from "/src/store/pixmeUploadStore";

const dropZoneStyle='.uploadBox {\n' +
    '    text-align: center;}\n' +
    '    .dropzone {\n' +
    '    cursor: pointer;\n' +
    '    flex: 1;\n' +
    '    display: flex;\n' +
    '    flex-direction: column;\n' +
    '    align-items: center;\n' +
    '    padding: 20px;\n' +
    '    border-width: 2px;\n' +
    '    border-radius: 2px;\n' +
    '    border-color: #52767f;\n' +
    '    border-style: dashed;\n' +
    '    background-color: #dde4e9;\n' +
    '    color: #094b74;\n' +
    '    outline: none;\n' +
    '    transition: border .24s ease-in-out;\n' +
    '}'



    export default function PixmeUpload({filesToUpload, rejectedFiles,  setFilesToUpload}) {

      const { t, onChangeLang } = useTranslation()
    // const uploadStore = useUploadStore();
    // const filesToUpload = useUploadStore((state) => state.filesToUpload)
        // const selectedPhotosCount=selectedPhotos.length;

/* this is if we want to resize thumbs to 400 px, but is very very slow
      const resizeImage = (file) => new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              const maxWidth = 400;
              let width = img.width;
              let height = img.height;

              if (width > maxWidth) {
                height = Math.floor((height * maxWidth) / width);
                width = maxWidth;
              }

              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(img, 0, 0, width, height);

              canvas.toBlob((blob) => {
                const previewUrl = URL.createObjectURL(blob);
                resolve({
                  ...file,
                  preview: previewUrl,
                  id: uuidv4(),
                  status: 'pending',
                  progress: 0,
                });
              }, file.type);
            };
          };
          reader.readAsDataURL(file);
        });
      const onDrop = useCallback((acceptedFiles) => {
        Promise.all(acceptedFiles.map(file => resizeImage(file)))
          .then(resizedFiles => setFilesToUpload(resizedFiles));

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
*/

    const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({

      accept: {
        'image/jpeg': ['.jpeg', '.jpg', '.jpe'],
        'image/webp': ['.webp'],
        'image/png': ['.png'],
        'image/gif': ['.gif'],
        'image/bmp': ['.bmp', '.bmp2', '.bmp3'],
        'image/tiff': ['.tif', '.tiff'],
        'image/x-canon-cr2': ['.cr2', '.cr3'],
        'image/x-sony-arw': ['.arw'],
        'application/postscript': ['.ai', '.eps', '.eps2', '.eps3', '.epsf', '.epsi'],
        'application/x-coreldraw': ['.cdr'],
        'text/plain': ['.txt'],
        'application/pdf': ['.pdf'],
        'application/vnd.msword': ['.doc', '.docx'],
        'application/vnd.ms-excel': ['.xls', '.xlsx'],
        'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
        'audio/mpeg': ['.mp3'],
        'audio/wav': ['.wav'],
        'audio/flac': ['.flac'],
        'video/mp4': ['.mp4'],
        'video/quicktime': ['.mov'],
        'video/mpeg': ['.mpeg', '.mpg'],
        'video/x-msvideo': ['.avi'],
        'application/x-gzip': ['.gz'],
        'application/x-tar': ['.tar'],
        'application/zip': ['.zip'],
        'application/vnd.rar': ['.rar'],
        'application/x-7z-compressed': ['.7z'],
        'application/x-iso9660-image': ['.iso'],
        'application/epub+zip': ['.epub'],
        'application/x-mobipocket-ebook': ['.mobi'],
        'application/octet-stream': [
          '.3fr', '.3g2', '.3gp', '.avi', '.cin', '.cmyk', '.crw', '.cur',
          '.dcm', '.dds', '.dng', '.dpx', '.erf', '.exr', '.fax', '.fff', '.fits',
          '.flv', '.heic', '.heif', '.ico', '.iiq', '.jbg', '.jng', '.k25', '.kdc',
          '.mef', '.mkv', '.mng', '.mos', '.mpc', '.mrw', '.nef', '.nrw', '.orf',
          '.otb', '.otf', '.pef', '.pict', '.psb', '.psd', '.raf', '.raw', '.rgb', '.rgba',
          '.rle', '.rw2', '.sfw', '.sgi', '.shtml', '.sr2', '.srf', '.srw',
          '.svg', '.swf', '.tga', '.tiff64', '.vda', '.vicar', '.webm', '.wmv',
          '.x3f', '.xhtml', '.xps', '.yaml', '.yuv', '.avif', '.jxl'
        ],
        'image/avif': ['.avif']
      },

       // eslint-disable-next-line no-shadow
      onDrop: acceptedFiles => {
        setFilesToUpload(
          acceptedFiles.map((file, index) => Object.assign(file, {
//              preview: index < 3 ? URL.createObjectURL(file) : null,
              preview:  null,
              id: uuidv4(),
              status: 'before',
              progress: 0
            }))
        );
      }


      // onDrop

        });





    // const filesToUpload=[...acceptedFiles];
    // const rejectedFiles=[...fileRejections];
    return (
        <section className="container">
            <style>
                {dropZoneStyle}
            </style>
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p className="uploadBox">
                    <CloudUploadIcon sx={{fontSize:"3em"}} /><br/>
                  <UploadIllustration sx={{ width: 1, maxWidth: 200 }} />
                  <Stack spacing={1} sx={{ textAlign: 'center' }}>
                    {t('upld.here_drop_files_to_upload')}<br/><br />
                    {t('upld.allowed_files')}: jpg, jpeg, png, gif, bmp, webp, pdf, doc, xls, xlsx, ppt, pptx, wav, mp3, mp4, mpg, mpeg, mov, avi, cdr, eps, ai.
                  </Stack>
                </p>
            </div>
        </section>
    )
}
PixmeUpload.propTypes = {
  filesToUpload:PropTypes.array,
  rejectedFiles:PropTypes.array,
  setFilesToUpload:PropTypes.func,
};
