import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField, FormControl, FormGroup, FormControlLabel, Checkbox, InputLabel, Select, MenuItem } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";




const ShareGalleryModal = ({ open, onClose, onSubmit, defaultValues }) => {
  const { t } = useTranslation();
  const [sharingValues, setSharingValues] = React.useState(defaultValues);

  const handleSharingChange = (field) => (event) => {
    setSharingValues((prev) => ({
      ...prev,
      [field]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('px.share_as_gallery')}</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="off">
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label={t('px.shared_gallery_name')}
              value={sharingValues.sharingName}
              onChange={handleSharingChange('sharingName')}
              fullWidth
            />
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 4 }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleSharingChange('allwDwnldFiles')} checked={sharingValues.allwDwnldFiles} />}
                label={t('px.allow_download')}
              />
              {sharingValues.allwDwnldFiles && (
                <Box sx={{ paddingLeft: 3 }}>
                  <FormControlLabel
                    control={<Checkbox onChange={handleSharingChange('dwnld1318')} checked={sharingValues.dwnld1318} disabled />}
                    label={t('px.downloadable_resolution_1318')}
                  />
                  <FormControlLabel
                    control={<Checkbox onChange={handleSharingChange('dwnldA4')} checked={sharingValues.dwnldA4} />}
                    label={t('px.downloadable_resolution_a4')}
                  />
                  <FormControlLabel
                    control={<Checkbox onChange={handleSharingChange('dwnldFull')} checked={sharingValues.dwnldFull} />}
                    label={t('px.downloadable_resolution_max')}
                  />
                </Box>
              )}
            </FormGroup>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 4 }}>
            <InputLabel>{t('px.shared_folder_validity')}</InputLabel>
            <Select
              value={sharingValues.sharingExpiration}
              onChange={handleSharingChange('sharingExpiration')}
            >
              <MenuItem value="3">{t('px.3_days')}</MenuItem>
              <MenuItem value="14">{t('px.14_days')}</MenuItem>
              <MenuItem value="30">{t('px.30_days')}</MenuItem>
              <MenuItem value="180">{t('px.6_months')}</MenuItem>
              <MenuItem value="9999">{t('px.no_expiration')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">{t('px.cancel')}</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onSubmit(sharingValues)}
          startIcon={<LinkIcon />}
          disabled={!sharingValues.sharingName.length}
        >
          {t('px.create_sharing')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareGalleryModal;

ShareGalleryModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.array,
};
