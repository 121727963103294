import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from 'react';

import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Chip,
  Switch,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ArrowBack, ArrowForward, ZoomIn } from '@mui/icons-material';
import Lightbox from 'yet-another-react-lightbox';
import {useTranslation} from "react-i18next";
import Image from "../components/image";
import 'yet-another-react-lightbox/styles.css';
import {useAxStore} from "../store/axStore";


const ModalLightboxForFilePreview = ({ slides, curindex, setIndex }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(curindex);
  const [lightboxIndex, setLightboxIndex] = useState(curindex);
  const [currentImageId, setCurrentImageId] = useState(curindex);
  const [detailedInfo, setDetailedInfo] = useState(null);
  const { t, onChangeLang } = useTranslation()

  const {
    selectedFiles_exists,
    selectedFiles_insert,
    selectedFiles_delete
  } = useAxStore();



  const [fileInfo, setFileInfo] = useState({
    caption: "Sunset over the lake",
    originalFileName: "IMG_12345.jpg",
    resolution: "1920x1080",
    dateTaken: "2023-06-15",
    dateUploaded: "2025-03-20",
    author: "John Doe",
    checked: true,
    license: "Creative Commons Attribution 4.0",
    systemAlert: "No issues detected",
    userInfo: "Updated by Alice Smith",
    licenseValidity: "2026-06-15",
    description: "A beautiful photo of a sunset taken during summer vacation.",
    storageInfo: [
      { resolution: "1920x1080", size: "2 MB" },
      { resolution: "1280x720", size: "1.2 MB" },
      { resolution: "640x480", size: "600 KB" },
    ],
    exifInfo: "Camera: Canon EOS 80D, Aperture: f/8.0, Shutter: 1/125s, ISO: 100",
    userTags: ["vacation", "sunset", "nature"],
    aiTagsEnglish: ["sunset", "lake", "clouds", "summer"],
    aiTagsLocal: ["západ slnka", "jazero", "oblaky", "leto"],
    directories: ["/Vacation/2023", "/Favorites"],
    downloadLog: [
      "Downloaded by Alice Smith on 2025-03-19",
      "Downloaded by Bob Johnson on 2025-03-18",
    ],
    processingLog: "Processed for resizing and color correction on 2025-03-20",
  });

  const handleSelect = useCallback(() => {
    if (selectedFiles_exists(currentImageId)) {
      selectedFiles_delete(currentImageId);
    } else {
      selectedFiles_insert(currentImageId);
    }
  }, [currentImageId, selectedFiles_exists, selectedFiles_insert, selectedFiles_delete]);

  // Event handler na počúvanie medzerníka
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "Space") {
        event.preventDefault(); // Zabraňuje posunutiu stránky
        handleSelect();
      }
    };

    if (curindex >= 0) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [curindex, handleSelect]);

  const fetchFileInfo = (index) => {
    const slide = slides[index];
    const imageId = slide ? slide.id : undefined;
    // console.log(`Fetching details for image ID: ${imageId}`);
    setCurrentImageId(imageId)
    const fetchedInfo = {
      title: `Názov obrázka ${imageId}`,
      description: `Popis obrázka ${imageId}`,
      date: "12.12.2023",
      properties: Array.from({ length: 5 }, (_, idx) => ({
        key: `Property ${idx + 1}`,
        value: `Value ${idx + 1}`
      })),
    };
    // setDetailedInfo(fetchedInfo);
    setDetailedInfo(null);
  };

  useEffect(() => {
    if (curindex !== -1) {
      setModalIndex(curindex);
      fetchFileInfo(curindex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curindex]);

  const handleOpenLightbox = () => {
    setLightboxOpen(true);
    setLightboxIndex(modalIndex);
  };

  const handleCloseLightbox = () => {
    console.log('closing with inbdex ',lightboxIndex)
    setLightboxOpen(false);
    setModalIndex(lightboxIndex);
    fetchFileInfo(lightboxIndex);
  };

  const handlePrevious = useCallback(() => {
    const newIndex = (modalIndex - 1 + slides.length) % slides.length;
    setModalIndex(newIndex);
    fetchFileInfo(newIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIndex, slides.length]);

  const handleNext = useCallback(() => {
    const newIndex = (modalIndex + 1) % slides.length;
    setModalIndex(newIndex);
    fetchFileInfo(newIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIndex, slides.length]);

  const handleKeyDown = (event) => {
    if (!lightboxOpen) {
      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        handlePrevious();
      }
      if (event.key === 'ArrowRight') {
        event.preventDefault();
        handleNext();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlePrevious, handleNext, lightboxOpen]);

  const handleCloseModal = () => {
    setLightboxOpen(false);
    setIndex(-1);
  };

  const currentFile = slides[modalIndex];

  return (
    <Modal
      open={curindex >= 0}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 3,
        maxWidth: 1100,
        width: '90%',
        maxHeight: '90vh', // Obmedzenie výšky na 90% viewportu
        overflowY: 'auto', // Umožní skrolovanie obsahu
        p: 4,
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography id="modal-title" variant="h6">
            {t("fileinfo.detailTitle")}
          </Typography>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box display="flex" alignItems="center">
          <IconButton onClick={handlePrevious}>
            <ArrowBack />
          </IconButton>

          <Box flexGrow={1} mx={2} sx={{ position: 'relative', transition: 'opacity 0.5s', opacity: 1 }}>
            <Image
              src={currentFile?.src}
              alt={currentFile?.alt}
              style={{ cursor: 'pointer', width: '100%', borderRadius: '5px' }}
              onClick={handleOpenLightbox}
            />
            <IconButton sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'rgba(255, 255, 255, 0.8)'
            }} onClick={handleOpenLightbox}>
              <ZoomIn />
            </IconButton>
          </Box>

          <IconButton onClick={handleNext}>
            <ArrowForward />
          </IconButton>
        </Box>


        {detailedInfo && (
          <Card>
            <CardContent>
              {/* Basic Information */}
              <Box mb={2}>
                <Typography variant="h6">{t("fileinfo.caption")}: {fileInfo.caption}</Typography>
                <Typography>{t("fileinfo.originalFileName")}: {fileInfo.originalFileName}</Typography>
                <Typography>{t("fileinfo.resolution")}: {fileInfo.resolution}</Typography>
                <Typography>{t("fileinfo.dateTaken")}: {fileInfo.dateTaken}</Typography>
                <Typography>{t("fileinfo.dateUploaded")}: {fileInfo.dateUploaded}</Typography>
                <Typography>{t("fileinfo.author")}: {fileInfo.author}</Typography>
              </Box>
              <Divider />

              {/* Action Bar */}
              <Stack direction="row" spacing={2} alignItems="center" mt={2} mb={2}>
                <IconButton aria-label={t("fileinfo.download")}>
                  <FileDownloadIcon />
                </IconButton>
                <IconButton aria-label={t("fileinfo.share")}>
                  <ShareIcon />
                </IconButton>
                <IconButton aria-label={t("fileinfo.delete")}>
                  <DeleteIcon />
                </IconButton>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Switch
                    checked={fileInfo.checked}
                    onChange={() => console.log("Checked state changed")}
                  />
                  <Typography>{t("fileinfo.checked")}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Checkbox
                    checked={selectedFiles_exists(currentImageId)}
                    onChange={handleSelect}
                  />
                  <Typography>{t("fileinfo.checked")}</Typography>
                </Stack>

              </Stack>
              <Divider />

              {/* License and Description */}
              <Box mb={2}>
                <Typography>{t("fileinfo.license")}: {fileInfo.license}</Typography>
                <Typography>{t("fileinfo.systemAlert")}: {fileInfo.systemAlert}</Typography>
                <Typography>{t("fileinfo.userInfo")}: {fileInfo.userInfo}</Typography>
                <Typography>{t("fileinfo.licenseValidity")}: {fileInfo.licenseValidity}</Typography>
                <Typography>{t("fileinfo.description")}: {fileInfo.description}</Typography>
              </Box>
              <Divider />

              {/* Accordion Section */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{t("fileinfo.storageInfo")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {fileInfo.storageInfo.map((info) => (
                      <Box key={info.resolution} mb={1}>
                        <Typography>
                          {t("fileinfo.resolution")}: {info.resolution}, {t("fileinfo.size")}: {info.size}
                        </Typography>
                      </Box>
                    ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{t("fileinfo.exifInfo")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{fileInfo.exifInfo}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{t("fileinfo.userTags")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {fileInfo.userTags.map((tag, index) => (
                    <Chip key={index} label={tag} sx={{ margin: 0.5 }} />
                  ))}
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{t("fileinfo.aiTagsEnglish")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {fileInfo.aiTagsEnglish.map((tag, index) => (
                    <Chip key={index} label={tag} sx={{ margin: 0.5 }} />
                  ))}
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{t("fileinfo.aiTagsLocal")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {fileInfo.aiTagsLocal.map((tag, index) => (
                    <Chip key={index} label={tag} sx={{ margin: 0.5 }} />
                  ))}
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{t("fileinfo.directories")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {fileInfo.directories.map((dir, index) => (
                      <Box key={index}>{dir}</Box>
                    ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{t("fileinfo.downloadLog")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {fileInfo.downloadLog.map((log, index) => (
                      <Box key={index}>{log}</Box>
                    ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{t("fileinfo.processingLog")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{fileInfo.processingLog}</Typography>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        )}

        <Lightbox
          slides={slides}
          open={lightboxOpen}
          index={lightboxIndex}
          close={handleCloseLightbox}
          on={{
            view: ({ index }) => {
              setLightboxIndex(index);
            },

          }}
          controller={{
            closeOnBackdropClick: true,
          }}
        />
      </Box>
    </Modal>
  );
};

ModalLightboxForFilePreview.propTypes = {
  slides: PropTypes.array,
  curindex: PropTypes.number,
  setIndex: PropTypes.func
};

export default ModalLightboxForFilePreview;
