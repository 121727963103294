import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';


import CloseIcon from '@mui/icons-material/Close';

import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import {TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import {useAxStore} from "../store/axStore";
import {RHFTextField} from "../components/hook-form";
import Iconify from "../components/iconify";
import {useSnackbar} from "../components/snackbar";
import {useBoolean} from "../hooks/use-boolean";
import axios from "../utils/axios";

const SendBugModal = ({open, setOpen}) => {

  const axStore=useAxStore();

  const { enqueueSnackbar } = useSnackbar();

  const [type,setType] = useState('');
  const [about,setAbout] = useState('');
  const [message,setMessage] = useState('');
  const bugIsSending = useBoolean(false);

  const [formData, setFormData] = useState({
    type: 1,
    about: 1,
    message: '',
    action:'report_bug',
    system:axStore.systemCurrentConfig.systemName==='piXme'?2:1,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async () => {
  if(!bugIsSending.value) {
    bugIsSending.onTrue()
    const jwt = localStorage.getItem('axjwt');
    if (jwt) {

      const postData = new FormData();
      Object.keys(formData).forEach(key => postData.append(key, formData[key]));



      axios.post('https://api.administrix.pro/v1/post/',
        postData, {
          headers: {'Authorization': `Bearer ${jwt}`}
        })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

            if (data.success === true) {

                enqueueSnackbar("Výborne, vašu správu sme prijali!", {variant: `success`, autoHideDuration: 6000});

              } else {
                enqueueSnackbar("Bohužiaľ, vašu správu sa nám nepodarilo doručiť :/", {variant: `error`, autoHideDuration: 6000});

              }


          } else if (response.data.error) {
//            const {error} = response.data;
            enqueueSnackbar(`Chyba pri zmene hesla dát: ${response.data.error.title}`, {variant: `error`, autoHideDuration: 6000});
            console.log('picxa hir')
          }

        }))
        .catch(error => {
          console.log("any changing passw  fetch api error");

        })

    }
    bugIsSending.onFalse()
  }
    setOpen(false)
  };

  const { t, onChangeLang } = useTranslation()




  return (

    <Dialog open={open}  maxWidth="sm" fullWidth >
        <DialogTitle>
          {t('report_issue_caption')}
          <IconButton
            aria-label="close"
             onClick={()=>setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>

            <Typography variant="subtitle2" noWrap sx={{textAlign:"center",marginBottom:"10px"}}>
              Direct support e-mail: {axStore.systemCurrentConfig.systemEmail}
            </Typography>
            {t('message_visibility_notice')}
  <Grid sm={12}>


      <FormControl fullWidth margin="normal">
        <InputLabel id="typ-label">Typ</InputLabel>
        <Select

          id="type"
          name="type"
          value={formData.type}
          onChange={handleChange}
          label="Typ"
        >
          <MenuItem value={1}>Chyba</MenuItem>
          <MenuItem value={2}>Návrh / pripomienka</MenuItem>
          <MenuItem value={0}>Neviem zaradiť</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel id="tykaSa-label">{t('tyka_sa')}</InputLabel>
        <Select

          id="about"
          name="about"
          value={formData.about}
          onChange={handleChange}
          label="Týka sa..."
        >
          <MenuItem value={0}>Neviem zaradiť</MenuItem>
          <MenuItem value={1}>Užívateľské prostredie systému</MenuItem>
          <MenuItem value={2}>Upload</MenuItem>
          <MenuItem value={3}>Vyhľadávanie</MenuItem>
          <MenuItem value={4}>Download</MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        margin="normal"
        id="message"
        name="message"
        label={t('description')}
        multiline
        rows={4}
        value={formData.message}
        onChange={handleChange}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth sx={{marginBottom:"20px",marginTop:"20px"}}>
        {t('send')}
      </Button>



  </Grid>
          </DialogContentText>
        </DialogContent>

      </Dialog>
  );
};
SendBugModal.propTypes = {
  open: PropTypes.bool,

  setOpen:PropTypes.func

};
export default SendBugModal;
